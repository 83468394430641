import Headroom from "headroom.js";
import SmoothScroll from "smooth-scroll";
import fslightbox from "fslightbox";
import vhCheck from "vh-check";
import "./contact-form";

const test = vhCheck({
    cssVarName: "vh-offset"
});

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true
});

const header = document.getElementById("site-header");
const headroom = new Headroom(header, {
    offset: header.offsetHeight / 2
});
headroom.init();

//BURGER MENU
const headerMenu = document.getElementById("js-headerMenu");
const headerOpen = document.getElementById("js-headerOpen");
const headerClose = document.getElementById("js-headerClose");
const headerOverlay = document.getElementById("js-headerOverlay");

headerOpen.addEventListener("click", () => {
    toggleHeaderMenu();
});

headerOverlay.addEventListener("click", () => {
    toggleHeaderMenu();
});

headerClose.addEventListener("click", () => {
    toggleHeaderMenu();
});

function toggleHeaderMenu() {
    headerMenu.classList.toggle("header-menu--is-open");
    headerOverlay.classList.toggle("header-overlay--is-active");
}

//Scroll up arrow
const scrollUp = document.getElementById("js-scrollUp");
if (scrollUp) {
    scrollUp.addEventListener("click", function() {
        scroll.animateScroll(0);
    });
}

function scrollUpToggle() {
    if (window.pageYOffset > 100) {
        if (window.oldScroll > window.scrollY) {
            scrollUp.classList.add("active");
        } else {
            scrollUp.classList.remove("active");
        }
    } else {
        scrollUp.classList.remove("active");
    }
    window.oldScroll = window.scrollY;
}

window.onscroll = function() {
    scrollUpToggle();
};
